<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters >

      <v-col cols="12">
        <p class="font-weight-bold">{{formatDuration(currentItinerary.duration)}}min</p>
      </v-col>

      <template v-for="(section) of currentItinerary.sections">
        <v-col cols="12" class="my-1">
          <v-row no-gutters v-if="['public_transport', 'street_network'].includes(section.type)" align="center">
            <v-col cols="1">
              <ItineraryIconMode
                :section="section" />
            </v-col>

            <!-- Public transport -->
            <v-col cols="9" v-if="section.type === 'public_transport'">
              <div
                class="ml-2">Direction <span class="font-weight-bold"> {{section.display_informations.direction}}</span></div>
            </v-col>

            <!-- Car no park -->
            <v-col cols="9" v-else-if="section.type === 'street_network' && section.mode === 'carnopark'">
              <div
                class="ml-2">
                Aller jusqu'à <span class="font-weight-bold">{{section.to.name}}</span></div>
            </v-col>

            <!-- Bss rent -->
            <v-col cols="9" v-else-if="section.type === 'street_network' && section.mode === 'bss_rent'">
              <div class="ml-2">Prendre un vélo à <span class="font-weight-bold">{{section.to.name}}</span></div>
            </v-col>

            <!-- Bike -->
            <v-col cols="9" v-else-if="section.type === 'street_network' && section.mode === 'bike'">
              <div class="ml-2">Rouler à vélo jusqu'à <span class="font-weight-bold">{{section.to.name}}</span></div>
            </v-col>

            <!-- Taxi -->
            <v-col cols="9" v-else-if="section.type === 'street_network' && section.mode === 'taxi'">
              <div class="ml-2">Prendre un taxi jusqu'à <span class="font-weight-bold">{{section.to.name}}</span></div>
            </v-col>

            <!-- Walking -->
            <v-col cols="9" v-else-if="section.type === 'street_network' && section.mode === 'walking'">
              <div
                class="ml-2">
                Marcher jusqu'à <span class="font-weight-bold">{{section.to.name}}</span></div>
            </v-col>

            <!-- Other -->
            <v-col cols="9" v-else>
              <div
                class="ml-2">
                Aller jusqu'à <span class="font-weight-bold">{{section.to.name}}</span></div>
            </v-col>

            <v-col cols="2" class="text-end">
              <span class="font-weight-bold mr-2">{{formatDuration(section.duration)}}min</span>
            </v-col>

            <v-col cols="12" class="mt-2">
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ItineraryDetails",

  components: {
    ItineraryIconMode: () => import("@/components/Common/ItineraryIconMode")
  },

  created() {
    if (!this.currentItinerary) {
      this.$router.replace({name: "Itinerary", query: this.$route.query});
    }

    if (this.$session.get("plan") === "FREE")
      this.$store.commit("setShowAdDialog", true);
  },

  methods: {
    formatDuration(duration) {
      const durationMin = Math.round(duration/60);
      if (durationMin === 0)
        return 0;
      if (durationMin > 100)
        return Math.round(durationMin/60);
      return durationMin;
    },

    getNextStops(section) {
      this.$http
        .get(`/map/viewer/next-stops?coords=${this.point.lat},${this.point.lng}&mode=${this.point.physical_mode}&stationID=${this.point.id}`, {
          headers: this.$session.exists() ? {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          } : {}
        })
        .then((res) => {
          //console.log(res.data);
          this.nextStops = res.data.departures;
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        })
    }
  },

  computed: {
    currentItinerary() {
      return this.$store.getters["itinerary/currentItinerary"];
    }
  }
}
</script>

<style scoped>

</style>